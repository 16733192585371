// Imports
import React, { useState, useEffect } from 'react';

// Import css
import './App.css';

// Import data
import { GlobalData } from './data/global';

// Import icons
import { FaHandPointRight } from "react-icons/fa";
import { MdOutlinePlace, MdOutlineMailOutline } from "react-icons/md";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, FaBars, FaTimes } from "react-icons/fa";

function App() {
  const [click, setClick] = useState(false);
  const handleMenuClick = () => setClick(!click);

  const handleRedirectTo = (url) => {
    window.open(url, "_blank");
  };

  const handleMenuOptionClick = () => {
    if (window.innerWidth < 1050) {
      setClick(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1050) {
        setClick(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <header>
        <section className="content-header">
          <div className="content-whatsapp">
            <h4 className="contact-with-whatsapp"><FaWhatsapp style={{ fontSize: '2rem', marginRight: '0.3rem' }} /> {GlobalData.header.contactWithWhatsapp}</h4>
          </div>

          <div className="content-socials">
            <button onClick={() => handleRedirectTo(`${GlobalData.socials.linkedIn}`)} > <FaLinkedin className="icons-header"/> </button>
            <button onClick={() => handleRedirectTo(`${GlobalData.socials.facebook}`)} > <FaFacebook className="icons-header"/> </button>
            <button onClick={() => handleRedirectTo(`${GlobalData.socials.instagram}`)} > <FaInstagram className="icons-header"/> </button>
            <button onClick={() => handleRedirectTo(`${GlobalData.socials.place}`)} > <MdOutlinePlace className="icons-header"/> </button>
          </div>
        </section>
      </header>

      <section className="content-menu menu">
        <div className="content">
          <img src="https://www.eduardogabriel.com.br/db/images/logo.png" alt="logo" id="logo" />

          <div className="menu-icons" onClick={handleMenuClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>

          <div className={`sub-content ${click ? 'active' : ''}`} id="menu-global">
            <nav className="options">
              <ul>
                <li> <a onClick={handleMenuOptionClick} href="#theoffice">O Escritório</a> </li>
                <li> <a onClick={handleMenuOptionClick} href="#areas-activity">Áreas de Atuação</a> </li>
                <li> <a onClick={handleMenuOptionClick} href="#oral-arguments">Sustentações Orais</a> </li>
                <li> <a onClick={handleMenuOptionClick} href="#contact">Contato</a> </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      <section className="content-main-photo"></section>

      <section className="content-the-office" id="theoffice">
        <div className="data">
          <div className="about-us">
            <h2>Advocacia & Consultoria</h2>
            <h1>Eduardo Gabriel</h1>
            <h4>Eu sou Eduardo Gabriel Souza Pereira, um advogado dedicado e apaixonado pela justiça,
              com minhas raízes na cidade de Presidente Prudente, interior de São Paulo. Em 2021,
              graduei-me na renomada Faculdade Toledo Prudente, onde dei os primeiros passos rumo à
              minha carreira jurídica com um propósito claro: defender os direitos daqueles que necessitam.
              <br /><br />
              Minha jornada acadêmica foi enriquecida pela busca constante pelo conhecimento e pela minha
              paixão pelo Direito Penal. Desde a faculdade, procurei destacar-me não apenas pelos excelentes
              resultados acadêmicos, mas também por minhas participações ativas em competições de direitos
              humanos e processo penal. Nessas competições, demonstrei não apenas habilidades jurídicas
              excepcionais, mas também um compromisso profundo com os valores fundamentais da justiça e da equidade.
              <br /><br />
              Após minha formatura, busquei me aprimorar ainda mais, realizando minha primeira pós-graduação em
              Direito Penal, Processo Penal e Criminologia pela PUCRS. Essa especialização refinada capacitou-me
              ainda mais para lidar com os aspectos mais intricados e desafiadores do sistema legal.
              <br /><br />
              Desde o início de minha prática profissional, tenho dado o meu máximo à advocacia criminal,
              com uma ênfase marcante na defesa dos acusados. Tenho marcado presença em Tribunais do júri
              e em casos envolvendo crimes de drogas, descaminho e contrabando, aplicando cada vez mais meu
              conhecimento especializado e minhas habilidades estratégicas.
              <br /><br />
              Para mim, ser advogado vai muito além das salas de audiência e das petições judiciais. Entendo
              profundamente os deveres éticos que carrego comigo: a transparência com meus clientes e a constante
              comunicação para mantê-los informados sobre cada passo do processo legal. É essa abordagem
              comprometida e atenciosa que me faz não apenas um defensor, mas um aliado confiável na busca pela justiça.</h4>
          </div>
          <div className="image-about-us">
            <div className="content-top-detail"></div>
            <div className="content-doctor"></div>
          </div>
        </div>
      </section>

      <section className="content-areas-activity" id="areas-activity">
        <div className="content-title">
          <h2 className="title-sections">Áreas de Atuação</h2>
          <div className="title-bar"></div>
        </div>

        <div className="data-areas-activity global-padding">
          <div className="areas">
            <h1>Nosso escritório é altamente capacitado para lidar com todas essas
              áreas do Direito Penal, garantindo uma análise minuciosa dos casos,
              compreensão das acusações, evidências e circunstâncias, além de
              oferecer uma defesa coesa e de excelência perante os tribunais.</h1>
            <h4> Direito Penal Clássico</h4>
            <h4> Direito Penal Econômico</h4>
            <h4> Lei de Drogas</h4>
            <h4> Violencia Doméstica</h4>
            <h4> Tribunal do Júri</h4>
            <h4> Prisão em Flagrante</h4>
          </div>
          <div className="areas-img"></div>
        </div>
      </section>

      <section className="content-pro-bono-advocacy" id="pro-bono-advocacy">
        <div className="content-title">
          <h2 className="title-sections">Advocacia Pro Bono</h2>
          <div className="title-bar"></div>
        </div>

        <div className="data-pro-bono-advocacy global-padding">
          <h1>Edital de seleção de processos</h1>
          <h4><span style={{ fontWeight: '600' }}>Maiores informações: </span>contato@eduardogabriel.com.br</h4>

          <h3 onClick={() => handleRedirectTo(`${GlobalData.proBono.form}`)} >Preencher o Formulário </h3>
        </div>
      </section>

      <section className="content-oral-arguments" id="oral-arguments">
        <div className="content-title">
          <h2 className="title-sections">Sustentações Orais</h2>
          <div className="title-bar"></div>
        </div>

        <div className="data-oral-arguments global-padding">
          <div className="argument">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/5DP-1K2-Ka4?si=oyf1c3DTBEx0Yz4Y"
              title="Sustentação TJSP"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowfullscreen>
            </iframe>
          </div>
        </div>
      </section>

      <section className="content-contact" id="contact">
        <div className="content-title">
          <h2 className="title-sections">Redes Sociais e Contato</h2>
          <div className="title-bar"></div>
        </div>

        <div className="contacts global-padding">
          <div className="socials">
            <div className="item"
              onClick={() => handleRedirectTo(`mailto:${GlobalData.socials.email}`)} >
                <MdOutlineMailOutline className="custom-icon" style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />
                contato@eduardogabriel.com.br
            </div>

            <div className="item"
              onClick={() => handleRedirectTo(`${GlobalData.socials.whatsapp}`)} >
                <FaWhatsapp className="custom-icon" style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />
                (18) 99666-2377
            </div>

            <div className="item"
              onClick={() => handleRedirectTo(`${GlobalData.socials.facebook}`)} >
                <FaFacebook className="custom-icon" style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />
                /eduardogabrieladvocaciacriminal
            </div>

            <div className="item"
              onClick={() => handleRedirectTo(`${GlobalData.socials.instagram}`)} >
                <FaInstagram className="custom-icon" style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />
                /eduuardoogabriel
            </div>

            <div className="item"
              onClick={() => handleRedirectTo(`${GlobalData.socials.linkedIn}`)} >
                <FaLinkedin className="custom-icon" style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />
                /Eduardo Gabriel - Advocacia e Consultoria
            </div>
          </div>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.2926461754732!2d-51.841647313537614!3d-21.884803850532176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x949165e64230f75d%3A0x1f287530ea58de92!2sEduardo%20Gabriel%20-%20Advocacia%20%26%20Consultoria!5e0!3m2!1sen!2sus!4v1712760347313!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={{ border:0 }}
              allowfullscreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="custom-map">
            </iframe>
          </div>
        </div>
      </section>

      <footer className="content-footer">
        <h4>
          © 2024 Eduardo Gabriel - Advocacia & Consultoria. <span onClick={() => handleRedirectTo(`${GlobalData.socials.ffhouse}`)} style={{ cursor: 'pointer' }}> Desenvolvido por <b>FFHouse</b>.</span>
        </h4>
      </footer>
    </div>
  );
}

export default App;