export const GlobalData = {
    header: {
        contactWithWhatsapp: '(18) 99666-2377 | Atendimento 24 horas via Whatsapp'
    },
    socials: {
        whatsapp: 'https://wa.me/5518996662377?text=Ol%C3%A1+Dr.+Eduardo+Gabriel%2C+acessei+o+seu+site+e+gostaria+de+solicitar+um+atendimento.',
        linkedIn: 'https://www.linkedin.com/company/eduardo-gabriel-advocacia-consultoria/',
        facebook: 'https://www.facebook.com/eduardogabrieladvocaciacriminal/',
        instagram: 'https://www.instagram.com/eduuardoogabriel/',
        place: '#',
        email: 'contato@eduardogabriel.com.br',
        ffhouse: 'https://www.ffhouse.com.br/'
    },
    proBono: {
        form: 'https://forms.gle/swEJt1aUhrg9n9yQ8'
    }
}

export const AreasOfActivity = [
    {
        icon: 'https://www.ffhouse.com.br/db/images/icon-test.png',
        activity: 'Direito Penal Clássico'
    },
    {
        icon: 'https://www.ffhouse.com.br/db/images/icon-test.png',
        activity: 'Direito Penal Econômico'
    },
    {
        icon: 'https://www.ffhouse.com.br/db/images/icon-test.png',
        activity: 'Lei de Drogas'
    },
    {
        icon: 'https://www.ffhouse.com.br/db/images/icon-test.png',
        activity: 'Violencia Doméstica'
    },
    {
        icon: 'https://www.ffhouse.com.br/db/images/icon-test.png',
        activity: 'Tribunal do Júri'
    },
    {
        icon: 'https://www.ffhouse.com.br/db/images/icon-test.png',
        activity: 'Prisão em Flagrante'
    }
]